<template>
  <Inspections stickyTop="64px"/>
</template>
<script>
import Inspections from '@/components/inspections/Inspections.vue'

export default {
  components: {
    Inspections,
  }
}
</script>
